import React from 'react';
import { Paper, Typography } from '@mui/material';
import { ResponsiveContainer, ComposedChart, Line, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const ComposedChartCard = ({
  title,
  subtitle,
  data,
  xAxisDataKey,
  lineDataKey,
  barDataKey,
  description,
}) => {
  return (
    <Paper>
      <Typography variant="h5" component="h2" gutterBottom marginLeft={6} paddingTop={2}>
        {title}
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom marginLeft={6}>
        {subtitle}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data} margin={{ top: 20, right: 20, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Line yAxisId="right" dataKey={lineDataKey} stroke="#AC5F2E" strokeWidth={3} />
          <Bar yAxisId="left"  dataKey={barDataKey} fill="#8884d8" />
        </ComposedChart>
      </ResponsiveContainer>
      <Typography variant="body1" gutterBottom marginLeft={6} paddingBottom={2}>
        {description}
      </Typography>
    </Paper>
  );
};

export default ComposedChartCard;
