


const dataContactos = [
    {
      name: 'John Doe',
      company: 'CFE Distribución Norte',
      phone: '123-456-7890',
      email: 'john@cfe.mx',
    },
    {
      name: 'Jane Smith',
      company: 'CFE Distribución Norte.',
      phone: '123-456-7890 ext 2020',
      email: 'jane@cfe.mx',
    },
    {
      name: 'Mark Johnson',
      company: 'CFE Distribución.',
      phone: '123-456-7890 ext 2345',
      email: 'mark@cfe.mx',
    },
    {
      name: 'Mark Johnson',
      company: 'CFE Distribución.',
      phone: '123-456-7890 ext 2345',
      email: 'mark@cfe.mx',
    },
    {
        name: 'Mark Johnson',
        company: 'Suministrador 1',
        phone: '123-456-7890 ext 2345',
        email: 'mark@suministro.mx',
      },
      {
        name: 'Mark Johnson',
        company: 'Suministrador 1',
        phone: '123-456-7890 ext 2345',
        email: 'mark@suministro.mx',
      },
      {
        name: 'Mark Johnson',
        company: 'Suministrador 1',
        phone: '123-456-7890 ext 2345',
        email: 'mark@suministro.mx',
      },
      {
        name: 'Mark Johnson',
        company: 'Suministrador 1',
        phone: '123-456-7890 ext 2345',
        email: 'mark@suministro.mx',
      },
      {
        name: 'Mark Johnson',
        company: 'Suministrador 1',
        phone: '123-456-7890 ext 2345',
        email: 'mark@suministro.mx',
      },
  ];
  
  export default dataContactos;
  