import { TextField, Button, Box, LinearProgress } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

const QueryForm = ({ onQueryChange }) => {
  const [BotQuestionQuery, setBotQuestionQuery] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading

  const handleInputChange = (event) => {
    setBotQuestionQuery(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading
    try {
        const response = await axios.post('https://bulbolabs.com/memdatabot', { user_input: BotQuestionQuery }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const assistantMessages = response.data.messages.filter(message => message.role === "assistant");
        let sqlQuery = assistantMessages[assistantMessages.length - 1].content;
        sqlQuery = sqlQuery.split("```")[1];
        onQueryChange(sqlQuery);
    } catch (error) {
        console.error("Error submitting query", error);
    } finally {
        setLoading(false); // Stop loading
    }
  };

  return (
    <Box>
        <form onSubmit={handleSubmit}>
            <TextField fullWidth id="filled-basic" label="Consulta" variant="filled" onChange={handleInputChange} />
            <Button variant='outlined' type="submit">Submit</Button>
            {loading && <LinearProgress />} {/* Render LinearProgress when loading is true */}
        </form>
    </Box>
  );
};

export default QueryForm;
