import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ChatbotContext from "./ChatbotContext";

const ChatbotProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const { user } = useAuth0();
  const auth0_id = user?.sub;

  const sendMessage = async (message, onResponse, targetApiAudience) => {
    setIsLoading(true);

    // Update the apiUrl to use the memchatbot route
    const apiUrl = "https://bulbolabs.com/memchatbot";

    // Include the auth0_id in the request body
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      
      body: JSON.stringify({ message, auth0_id }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      console.log("Raw response:", response);
      const data = await response.json();
      console.log("Data from chatbot:", data);

      if (data && data.sender && data.text && data.text.output_text) {
        setIsLoading(false);
        setSource((prevState) => [...prevState, ...data.source]);
        console.log("Updated source data:", data.source);
        onResponse(data);
      } else {
        throw new Error("No response from chatbot");
      }
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <ChatbotContext.Provider value={{ auth0_id, sendMessage, isLoading, source }}>
    {children}
    </ChatbotContext.Provider>
  );
};

export default ChatbotProvider;
