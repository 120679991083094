

import React from 'react';
import { Paper, Card, CardContent, Typography, Divider } from '@mui/material';

const CardInfo = ({ DataTitle , data , subtitle  }) => {
    return (
        <Paper>
            <Card>
            <CardContent>
                <Typography
                gutterBottom
                color="TextPrimary"
                variant = "h5">
                    {DataTitle}
                </Typography> 
                <Divider />           
                <Typography variant="h4" align = "right">
                {data} 
                </Typography>
                <Typography variant="subtitle1" align = "right">
                {subtitle}
                </Typography>
            </CardContent>
            </Card>
        </Paper>
    );
};

export default CardInfo;

