import React from 'react';
import {  Paper, Typography } from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const BarChartCard = ({ title, subtitle, data, xAxisDataKey, barDataKey, Description }) => {
  return (
    <Paper >
      <Typography variant="h5" component="h2" gutterBottom marginLeft={6} paddingTop={2}>
        {title}
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom marginLeft={6}>
        {subtitle}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
          <BarChart
              data={data}
              margin={{ top: 20, right: 20, left: 0, bottom: 5 }}   
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} />
            <YAxis />
            <Tooltip />
            <Bar dataKey={barDataKey} fill="#063971" />
          </BarChart>
      </ResponsiveContainer>
      <Typography variant="body1" gutterBottom marginLeft={6} paddingBottom={2}>
          {Description}
      </Typography>
    </Paper>
  );
};

export default BarChartCard;
