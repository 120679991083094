import React from 'react';
import { Grid, Paper, TextField, Typography, Button } from '@mui/material';
import DashboardLayout from '../common/dashboardLayout';
import { Container } from '@mui/system';

const UserProfile = () => {
  return (
    <DashboardLayout>
      <Container>      
        <Grid container spacing={3} marginTop={10}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ padding: (theme) => theme.spacing(2), textAlign: 'center', color: (theme) => theme.palette.text.secondary }}>
              <Typography variant="h6">User Profile</Typography>
              <form>
                <TextField
                  sx={{ marginBottom: (theme) => theme.spacing(2) }}
                  fullWidth
                  id="name"
                  label="Name"
                  variant="outlined"
                />
                <TextField
                  sx={{ marginBottom: (theme) => theme.spacing(2) }}
                  fullWidth
                  id="email"
                  label="Email"
                  variant="outlined"
                />
                <TextField
                  sx={{ marginBottom: (theme) => theme.spacing(2) }}
                  fullWidth
                  id="phone"
                  label="Phone"
                  variant="outlined"
                />
                <TextField
                  sx={{ marginBottom: (theme) => theme.spacing(2) }}
                  fullWidth
                  id="address"
                  label="Address"
                  variant="outlined"
                />
                <Button variant="contained" color="primary">
                  Save Changes
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default UserProfile;
