import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import CostosDashboard from "./components/CostosDashboard";
import MEMDashboard from "./components/MEMDashboard";
import Login from "./components/Login/Login";
import UserProfile from "./components/UserProfile";
import DirectorioDeContactos from './components/DirectorioDeContactos';
import Facturacion from './components/Facturacion';
import MEMchatbot from './components/MEMchatbot';
import MEMdatabot from './components/MEMdataBot';
import ProtectedRoute from './components/common/ProtectedRoute';
import MEMdatabotSimple from './components/MEMdataBotSimple';


const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    
    <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
    <Route path="/costosdashboard" element={<ProtectedRoute component={CostosDashboard} />} />
    <Route path="/memdashboard" element={<ProtectedRoute component={MEMDashboard} />} />
    <Route path="/userprofile" element={<ProtectedRoute component={UserProfile} />} />
    <Route path="/contactos" element={<ProtectedRoute component={DirectorioDeContactos} />} />
    <Route path="/facturacion" element={<ProtectedRoute component={Facturacion} />} />
    <Route path="/MEMchatbot" element={<ProtectedRoute component={MEMchatbot} />} />
    <Route path="/MEMdataBot" element={<ProtectedRoute component={MEMdatabot} />} />
    <Route path="/MEMdatabotSimple" element={<ProtectedRoute component={MEMdatabotSimple} />} />
  </Routes>
);

export default AppRoutes;
