import React, { useState } from 'react';
import { LinearProgress, Select, MenuItem, Grid, Typography, Container } from '@mui/material';

import DashboardLayout from '../common/dashboardLayout';
import QueryForm from './Queryform';
import MEMDataTable from './MEMDataTable';

import MEMLineCharts from './MEMLineCharts';

const MEMdatabotSimple = () => {
    const [result, setResult] = useState(null);
    const [chartDataField, setChartDataField] = useState('');
    const [loading, setLoading] = useState(false);

    console.log("result state in MEMdatabotSimple", result); // check the result state 

    let dataKeys = result && result.length > 0 ? 
        Object.keys(result[0]).filter(key => key !== 'fecha' && key !== 'id') : [];
    
    console.log("dataKeys", dataKeys); // check the keys being passed to the Select component

    return (
        <DashboardLayout>
            <Container >
                <Grid container spacing={1}>
                    <Grid md={12} sm={12}>
                        <Typography variant='h4'>MEM Data Bot</Typography>
                        <Typography variant='p'>Consulta la informacion del mercado preguntando en lenguaje natural <br /> <br /></Typography>
                        <Typography variant='p'>Ej. cual es el precio promedio en la zona aguascalientes en marzo 2023?<br /></Typography>
                    </Grid>
                    <Grid item sm={12}  md={12} marginTop={2}>
                        <QueryForm onQueryChange={(data) => { console.log('Data from QueryForm', data); setResult(data); }} setLoading={setLoading} />
                    </Grid>
                    <Grid sm={12} md={12}  minHeight={400} marginTop={2}>
                        <Typography padding={1} variant='h6'>Data</Typography>
                        {loading && <LinearProgress />}
                        <MEMDataTable data={result} />
                    </Grid>
                    <Grid sm={12} md={12} minHeight={400} marginTop={2}>
                        <Typography padding={1} variant='h6'>Chart</Typography>
                        <Select
                            value={chartDataField}
                            onChange={(e) => setChartDataField(e.target.value)}
                        >
                            {dataKeys.map((key) => (
                                <MenuItem key={key} value={key}>{key}</MenuItem>
                            ))}
                        </Select>
                        <MEMLineCharts data={result} dataKey={chartDataField} />                        
                    </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    );
};

export default MEMdatabotSimple;
