import React from 'react';
import { Button, Divider, Grid, Card, Box, CardContent, Typography, Container, Paper } from '@mui/material';

///Componentes de la app
import BarChartCard from '../charts/BarChartCard';
import LineChartCard from '../charts/LineChartCard';
import DashboardLayout from '../common/dashboardLayout';
import { useApi } from '../context/apiBulbocontext';


const Dashboard = () => {
    const {
      monthlyTotal,
      dailyAverage,
      peakHourAverage,
      missingHours,
      dailyMonthlyConsumption,
      peakHourDemand,
      contractedVsDailyDemand,
      monthlyTrend,
      consumoMWh,
    } = useApi();

  return (
    <DashboardLayout>
    <Container 
    sx={{
      minHeight: '100vh',
      padding: { sm: '0', xs: '0' },
    }}>
      <Grid container spacing={1} padding={1}>
        <Grid item xs={12} md={12}>
            <Paper elevation ={2}>
                <Typography variant="h4" marginLeft={1} padding={1}>
                    Acme SA  
                </Typography>
                <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="h5" marginLeft={1} marginTop={1} padding={1}>
                    Octubre 2021
                  </Typography>
               </Grid>
            </Paper>
        </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper>
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    color="TextPrimary"
                    variant = "h5">
                      MWh Consumo Mes
                  </Typography> 
                  <Divider />           
                  <Typography variant="h4" align = "right">
                    {monthlyTotal && monthlyTotal.total_mwh_consumption} 
                  </Typography>
                  <Typography variant="subtitle1" align = "right">
                    -2% vs mes anterior
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper>
                <Card>
                  <CardContent>
                    <Typography
                      gutterBottom
                      color="TextPrimary"
                      variant = "h5">
                        Demanda MW
                    </Typography> 
                    <Divider />           
                    <Typography variant="h4" align = "right">
                      {dailyAverage && dailyAverage.total_mwh_consumption}
                    </Typography>
                    <Typography variant="subtitle1" align = "right">
                        Promedio diario
                    </Typography>
                  </CardContent>
                </Card>
               </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper>
                <Card>
                  <CardContent>
                    <Typography
                      gutterBottom
                      color="TextPrimary"
                      variant = "h5">
                        Demanda Pico MW
                    </Typography> 
                    <Divider />           
                    <Typography variant="h4" align = "right">
                      {peakHourAverage && peakHourAverage.average_consumption_mwh}
                    </Typography>
                    <Typography variant="subtitle1" align = "right">
                        Promedio 7 - 9 PM
                    </Typography>
                  </CardContent>
                </Card>
               </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper>
                <Card>
                  <CardContent>
                    <Typography
                      gutterBottom
                      color="TextPrimary"
                      variant = "h5">
                        Horas sin medición
                    </Typography> 
                    <Divider />           
                    <Typography variant="h4" align = "right">
                        {missingHours && missingHours.missing_hours} 
                    </Typography>
                    <Typography variant="subtitle1" align = "right">
                        Datos faltantes
                    </Typography>
                  </CardContent>
                </Card>
               </Paper>
            </Grid>
         
        
           <Grid item xs={12} md={12}>
              <LineChartCard
                title = 'Demanda horaria'
                subtitle = "MW horario"
                data = {consumoMWh}
                xAxisDataKey = "fecha"
                LineDataKey ="consumo_mwh"
                Description =" Consumo durant ccada hora "
                >
              </LineChartCard>  
            </Grid>          
           
            <Grid item xs={12} md={6}>
              <BarChartCard
              title = 'Demanda Promedio'
              subtitle = 'MWh'
              data ={dailyMonthlyConsumption}
              xAxisDataKey = "day_of_month"
              barDataKey = "daily_average_consumption_mwh"
              Description = "Consumo promedio de 24 h durante el mes"
              >
              </BarChartCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <LineChartCard
              title = 'Demanda en Hora Pico'
              subtitle = "MW"
              data = {peakHourDemand}
              xAxisDataKey = "day"
              LineDataKey ="demanda_promedio_hora_pico"
              LineDataKey2 ="demanda_max_hora_pico"
              Description ="Consumo promedio entre las 19-21 h"
              >
              </LineChartCard>            
            </Grid>
            <Grid item xs={12} md={6}>
              <LineChartCard
                title = 'Demanda Consumida vs Contratada'
                subtitle = "MW pomedio diario"
                data = {contractedVsDailyDemand}
                xAxisDataKey = "day_of_month"
                LineDataKey ="demanda_promedio_diaria"
                LineDataKey2 ="demanda_contratada_mw"
                Description ="20% de las horas arriba de la demanda contratada"
                >
              </LineChartCard>  
            </Grid>
            <Grid item xs={12} md={6}>
              <BarChartCard
              title = 'Histórico Mensual'
              subtitle = 'MWh'
              data ={monthlyTrend}
              xAxisDataKey = "year_month"
              barDataKey = "monthly_consumption_mwh"
              Description = "Consumo mensual ultimos 12 meses"
              >
              </BarChartCard>
            </Grid>
        <Box marginBottom={8} marginTop={8} sm ={{marginLeft: 10}}>
          <a href="https://drive.google.com/uc?id=1Q5VKgfPe2uzaEBUtTJTl6XgXeK_mG-bf&export=download">
            <Button variant="contained">Descarga Datos</Button>
          </a>
        </Box>
        </Grid>
    </Container>
  </DashboardLayout>
  );
};

export default Dashboard;


