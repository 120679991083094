import { TextField, Button, Box, LinearProgress } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const QueryForm = ({ onQueryChange, setLoading, loading }) => { 
  const [BotQuestionQuery, setBotQuestionQuery] = useState("");
  const { user } = useAuth0();
  const auth0_id = user?.sub;

  const handleInputChange = (event) => {
    setBotQuestionQuery(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); 
    try {
        const response = await axios.post('https://bulbolabs.com/memdatabots_execute', { user_input: BotQuestionQuery, auth0_id: auth0_id }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log('Received response:', response.data);
        onQueryChange(response.data);
        console.log('Passed data to parent component:', response.data);
    } catch (error) {
        console.error("Error submitting query", error);
    } finally {
        setLoading(false); 
    }
  };

  return (
    <Box>
        <form onSubmit={handleSubmit}>
            <TextField fullWidth id="filled-basic" label="Consulta" variant="filled" onChange={handleInputChange} />
            <Button variant='outlined' type="submit">Submit</Button>
            {loading && <LinearProgress />} {/* Render LinearProgress when loading is true */}
        </form>
    </Box>
  );
};

export default QueryForm;
