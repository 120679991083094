// MEMdatabot.js

import React, { useState } from 'react';
import axios from 'axios';
import { LinearProgress, Select, MenuItem, Grid, Typography, Container, Button, Paper } from '@mui/material';

///Componentes de la app
import DashboardLayout from '../common/dashboardLayout';
import QueryForm from './Queryform';
import MEMDataTable from './MEMDataTable';
import MEMLineChart from './MEMLineChart';

const MEMdatabot = () => {
    const [query, setQuery] = useState("");
    const [result, setResult] = useState(null);
    const [chartDataField, setChartDataField] = useState('');
    const [loading, setLoading] = useState(false);

    const handleExecuteQuery = async () => {
        setLoading(true); // Start the loading indicator
        try {
            const response = await axios.post('https://bulbolabs.com/memdatabot_execute', { query });
            setResult(response.data);
        } catch (error) {
            console.error("Error executing query", error);
        } finally {
            setLoading(false); // Stop the loading indicator
        }
    };
    
    const downloadData = async () => {
        try {
          const response = await fetch('https://bulbolabs.com/memdatabot_download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query })
          });
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'BULBOAI_MEM_data.csv';
          document.body.appendChild(a);
          a.click();    
          a.remove();
        } catch (error) {
          console.error("Error downloading data", error);
        }
    };
    let dataKeys = result && result.length > 0 ? 
        Object.keys(result[0]).filter(key => key !== 'fecha' && key !== 'id') : [];
    
    return (
        <DashboardLayout>
            <Container >
                <Grid 
                    container
                    spacing={1}
                >
                    <Grid md ={12} sm ={12}>
                        <Typography variant='h4'>MEM Data Bot</Typography>
                        <Typography variant='p'>consulta la informacion del mercado preguntando en lenguaje natural <br /> <br /></Typography>
                        <Typography variant='p'>ej. cual es el precio promedio en la zona aguascalientes en marzo 2023?<br /></Typography>
                    </Grid>
                    <Grid item sm = {12}  md = {12} marginTop={2} >
                        <QueryForm onQueryChange={setQuery} />
                   
                    </Grid>
                    <Grid item sm={12} md ={12} marginTop={2} >
                        {loading && <LinearProgress />}
                        <Typography>
                            Query
                        </Typography>
                        <Paper padding={1} style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            <code>
                                {query} 
                            </code>
                        </Paper>
                        <Button variant='outlined' onClick={handleExecuteQuery}>
                            Ejecutar Consulta
                        </Button>
                    </Grid>
                    <Grid sm ={12} md={6}  minHeight={400} marginTop={2}>
                        <Typography padding={1} variant='h6'>Data</Typography>
                        <MEMDataTable data={result} />
                        <Button variant='outlined' onClick={downloadData}>
                            Descargar Datos
                        </Button>
                    </Grid>
                    <Grid sm={12} md={6} minHeight={400} marginTop={2}>
                        <Typography padding={1} variant='h6'>Chart</Typography>
                        <Select
                        value={chartDataField}
                        onChange={(e) => setChartDataField(e.target.value)}
                        >
                        {dataKeys.map((key) => (
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ))}
                        </Select>
                        <MEMLineChart data={result} dataKey={chartDataField} />
                    </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    );
};

export default MEMdatabot;