

import {  Grid, Box, Typography, Container } from '@mui/material';
import React, { useContext } from 'react';
import ChatbotContext from './ChatbotContext';
///Componentes de la app
import ChatbotWindow from './chatbotWindow';
import ChatbotProvider from './ChatbotProvider';
import DashboardLayout from '../common/dashboardLayout';
import SourceFeed from './SourceFeed';


const MEMchatbot = () =>{
    const { source } = useContext(ChatbotContext);

    return (
        <DashboardLayout>
            <Container >
                <Grid 
                    container 
                    spacing={1}
                >
                    <Grid item sm ={12}>
                    <Typography variant='h4' align='center'>
                            AI Generativa para energía
                        </Typography>
                        <Typography variant='h6' marginBottom={2} align='center'>
                            Busqueda semántica del sector eléctrico Méxicano
                        </Typography>
                    </Grid>
                    <Grid item md = {8} sm={12} >
                        <Box >
                            <ChatbotWindow></ChatbotWindow>
                        </Box>
                    </Grid>
                    <Grid item md = {4} sm={12}>
                    <Typography variant='subtitle1' padding={1}>
                            Referencias
                        </Typography>
                        
                        <SourceFeed source={source} />
                        
                        </Grid>
                </Grid>
            </Container>
        </DashboardLayout>
    );
};

const WrappedMEMchatbot = () => (
    <ChatbotProvider>
    <MEMchatbot />
    </ChatbotProvider>
);

export default WrappedMEMchatbot;
