// MEMDataTable.js
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const MEMDataTable = ({ data }) => {
  if (!data || data.length === 0) return <div>No data</div>;

  // Create columns for the data grid from the data keys
  const columns = Object.keys(data[0]).map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1), // capitalize the header name
    width: 150
  }));

  // Add an id to each row for the grid
  const rows = data.map((row, index) => ({ id: index, ...row }));

  return (
    <Paper style={{ height: 400, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} />
    </Paper>
  );
};

export default MEMDataTable;
