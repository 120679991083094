import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  console.log('isLoading:', isLoading);
  console.log('isAuthenticated:', isAuthenticated);
  console.log('error:', error);

  if (isLoading) {
    console.log("ProtectedRoute is loading...");
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Component />
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
