import {  Paper, Typography } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Energia', value: 1500 },
  { name: 'Potencia', value: 300 },
  { name: 'CELs', value: 100 },
  { name: 'Regulados', value: 300 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TariffChartCard = () => {
  return (
    <Paper>
      <Typography variant="h5" component="h2" gutterBottom marginLeft={6} paddingTop={2}>
        Composición Tarifa
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx={250}
          cy={100}
          outerRadius={100}
          innerRadius={70}
          paddingAngle={2}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend/>
      </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default TariffChartCard;
