import React, { useState } from 'react';
import { Alert, AlertTitle, Collapse } from '@mui/material';

function CenaceSystemAlert({ data = {} }) {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const handleSeverity = (estado) => {
    if (estado === 'Emergencia') {
      return 'error';
    } else if (estado === 'Alerta') {
      return 'warning';
    } else {
      return 'info';
    }
  }

  if (!data.complete_alert_text) {
    return null;
  }

  return (
    <Alert severity={handleSeverity(data.estado)} variant='outlined' onClick={handleClick}>
      <AlertTitle>{`Estado de ${data.estado} en Gerencia ${data.gerencia}`}</AlertTitle>
      <Collapse in={expanded}>
        {data.complete_alert_text}
      </Collapse>
      {!expanded && (
        <div onClick={handleClick}>
          {data.complete_alert_text.slice(0, 100)}...
        </div>
      )}
    </Alert>
  );
}

export default CenaceSystemAlert;
