import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import AppRoutes from './routes';
import { ApiProvider } from './components/context/apiBulbocontext';

function App() {
  const onRedirectCallback = (appState) => {
    console.log("onRedirectCallback - appState:", appState);
    const returnTo = appState?.returnTo || window.location.pathname || '/dashboard';
    console.log("onRedirectCallback - returnTo:", returnTo);
    window.history.replaceState({}, document.title, returnTo);
  };

  return (
    <Auth0Provider
      domain="dev-4d060bhbvlw27jhl.us.auth0.com"
      clientId="dTw3RPPaPIoi8RCNbfmnTJPaWXUIU6gM"
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: "https://bulbolabs.com/api",
        scope: "openid profile post"
      }}
    >
      <Router>
        <ApiProvider>
          <AppRoutes />
        </ApiProvider>
      </Router>
    </Auth0Provider>
  );
}

export default App;
