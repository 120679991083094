// MEMLineChart.js
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const MEMLineCharts = ({ data = [], dataKey = '', extraKeys = [] }) => {
  
  const [maxYValue, setMaxYValue] = useState(0);

  useEffect(() => {
    if (!data) {
      return;
    }
  
    // Extract the y-values from the data
    let yValues = data.map(item => item[dataKey]);
  
    // Compute the max y-value
    let maxY = Math.max(...yValues);
  
    // Set the max y-value state
    setMaxYValue(maxY);
  }, [data, dataKey]);

  return (
    <Paper>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart margin={{ top: 20, right: 30, bottom: 10, left: 10 }} data={data} >
          <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
          <XAxis dataKey="fecha" />
          <YAxis domain={[0, maxYValue + maxYValue*0.1]} />
          <Line type="monotone" dataKey={dataKey} stroke="#8884d8" strokeWidth={1}/>
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default MEMLineCharts;
