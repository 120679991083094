
import { Grid, Box, Typography } from '@mui/material';
import { Container } from '@mui/system';


//componentes internos
import ContactTable from '../common/ContactTable'
import CenaceSystemAlert from '../common/CenaceSystemAlert'
import dataContactos from './dataContactos';
import DashboardLayout from '../common/dashboardLayout';
import { useApi } from '../context/apiBulbocontext';

function DirectorioDeContactos() {
  const {
    alertasEstadoOperativo,
  } = useApi();
  return (
    <DashboardLayout>
      <Container
      sx={{
      minHeight: '100vh',
      padding: { sm: '0', xs: '0' },
      }}>
          <Grid container spacing={4} padding={4}>
            <Grid item xs={12} md={12}>
            <Typography variant='h4'>
                  Contactos 
              </Typography>
            </Grid>
              <Grid item xs={12} md = {8} >
                  <Box sx={{ display:'flex',  marginTop:5 }}>
                      <ContactTable data={dataContactos} />
                  </Box>
              </Grid>
              <Grid item xs={12} md ={4}>
                      <Box  sx={{ display:'flex', marginTop:1}}>
                      <Grid item xs={12} md={12}>
                      <Typography variant='h6'>Alertas Estado Operativo</Typography>
                      <Grid container spacing={1}>
                      {alertasEstadoOperativo.map((alert) => (
                      <Grid item xs={12} key={alert.clave}>
                          <CenaceSystemAlert data={alert} />
                      </Grid>
                      ))}
                  </Grid>
              </Grid>
              </Box>
              </Grid>
          </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default DirectorioDeContactos;
