import { Grid, Paper, Typography, Link } from '@mui/material';
import { Container } from '@mui/system';


import CardInfo from '../common/CardInfo';
import LineChartCard from '../charts/LineChartCard';
import CenaceSystemAlert from '../common/CenaceSystemAlert';
import DashboardLayout from '../common/dashboardLayout';
import { useApi } from '../context/apiBulbocontext';

function MEMDashboard() {
  const {
    gerencia10Data,
    alertasEstadoOperativo,
    dailyIndicadores,
  } = useApi();


  const precio_sin = gerencia10Data.map((item) => ({
    hora: item.hora,
    precio_sin: parseFloat(item.precio_sin),
    fechaMEM: item.date,
  }));

  const TipoCambiodate = dailyIndicadores.length > 0 ? dailyIndicadores[0].date : null;

  const indicadoresData = dailyIndicadores.reduce((obj, item) => {
      obj[item.indicador] = item.value;
      return obj;
    }, {});
  
  const maxPrecioSin = precio_sin.reduce((max, item) => {
  if (item.precio_sin > max.value) {
      return { hora: item.hora, value: item.precio_sin };
  }
  return max;
  }, { hora: null, value: Number.NEGATIVE_INFINITY });
  
  const maxValorPronostico = gerencia10Data.reduce((max, item) => {
      if (item.valor_pronostico > max.value) {
        return { hora: item.hora, value: item.valor_pronostico };
      }
      return max;
    }, { hora: null, value: Number.NEGATIVE_INFINITY });

    const avgPrecioSin =
    Math.round(
      (precio_sin.reduce((sum, item) => sum + item.precio_sin, 0) / precio_sin.length) * 100
    ) / 100;
      
    return (
   <DashboardLayout>
    <Container
    
    sx={{
      
      minHeight: '100vh',
      padding: { sm: '0', xs: '0' },
    }}
    >
      <Grid container spacing={1} padding={1}>
        <Grid item xs={12} md={12}>
            <Paper>
                <Typography variant='h4' padding = {1}>
                    Status Mercado Eléctrico
                </Typography>
                <Typography variant='h5' padding = {1}>
                {precio_sin.length > 0 ? precio_sin[0].fechaMEM : null}
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle= "Precio máximo del día"
          data= {maxPrecioSin.value}
          subtitle= {`MXN/MWh | Registro en Hora: ${maxPrecioSin.hora}`}
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Tipo de cambio"
          data={indicadoresData['DOLAR']}
          subtitle={`Actualizado: ${TipoCambiodate}`}
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Precio Henry Hub"
          data = "2.60"
          subtitle="USD/ MMBTU"
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Demanda Max Sistema"
          data = {maxValorPronostico.value}
          subtitle={`MW | Hora esperada: ${maxValorPronostico.hora}`}
          >
          </CardInfo>
        </Grid>
        <Grid item xs ={12} md ={8}>
        <LineChartCard
        title="Demanda Sistema Interconectado Nacional"
        subtitle="MWh"
        data={gerencia10Data}
        xAxisDataKey="hora"
        start_yvalue={34}
        LineDataKey="valor_pronostico"
        LineDataKey2="valor_generacion"
        LineDataKey3="valor_demanda"
        Description="Demanda horaria del Sistema Interconectado Nacional, no incluye sistemas de Baja California"
            />
        <LineChartCard
        title="PML Promedio Sistema Interconectado Nacional"
        subtitle="MXN/MWh"
        data={precio_sin}
        xAxisDataKey="hora"
        LineDataKey="precio_sin"
        Description={`Promedio del día ${avgPrecioSin} MXN/MWh para el SIN, no incluye BCA,BCS`}
        />
        </Grid>
        <Grid item xs={12} md={4}>
            <Typography variant='h6'>Alertas Estado Operativo</Typography>
            <Link marginBottom={1} href="https://www.cenace.gob.mx/Paginas/SIM/EstadoOperativoSEN.aspx" target="_blank" rel="noopener noreferrer">Fuente 
            </Link>
        <Grid container spacing={2}>
            {alertasEstadoOperativo.map((alert) => (
            <Grid item xs={12} key={alert.clave}>
                <CenaceSystemAlert data={alert} />
            </Grid>
            ))}
        </Grid>
        </Grid>
        <Grid item xs ={12} md ={6}>
        </Grid>
      </Grid>
    </Container>
  </DashboardLayout>
  );
};

export default MEMDashboard;
