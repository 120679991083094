import React from 'react';
import {  Paper, Typography } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const LineChartCard = ({   title, subtitle, data, xAxisDataKey, LineDataKey, LineDataKey2, LineDataKey3, Description }) => {
  return (
    <Paper >
      <Typography variant="h5" component="h2" gutterBottom marginLeft={6} paddingTop={2}>
        {title}
      </Typography>
      <Typography variant="subtitle1" component="h3" gutterBottom marginLeft={6}>
        {subtitle}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
          <LineChart
              data={data}
              margin={{ top: 20, right: 20, left: 20, bottom: 5 }}   
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} />
            <YAxis domain={['auto', 'auto']}/>
            <Tooltip />
            <Line dataKey={LineDataKey} stroke="#AC5F2E" strokeWidth={1} />
            <Line dataKey={LineDataKey2} stroke="#063971" strokeWidth={2} />
            <Line dataKey={LineDataKey3} stroke="#063971" strokeWidth={2} />
          </LineChart>
      </ResponsiveContainer>
      <Typography variant="body1" gutterBottom marginLeft={6} paddingBottom={2}>
          {Description}
      </Typography>
    </Paper>
  );
};

export default LineChartCard;
