import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Hidden, AppBar, Toolbar } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ContactsIcon from '@mui/icons-material/Contacts';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, styled } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PowerIcon from '@mui/icons-material/Power';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  marginTop: theme.spacing(8),
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    marginTop: theme.spacing(7),
  },
  '& .MuiDrawer-modal': {
    marginTop: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
      marginTop: 80,
    },
    '& .MuiDrawer-modal': {
      marginTop: 80,
    },
  },
}));


const ActiveMenuItem = styled(ListItem)(({ theme }) => ({
  '&.active': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  textDecoration: 'none',
  color: 'inherit',
}));

const menuItems = [
  { text: 'Suministro Calificado', path: '/Dashboard', icon: <PowerIcon />, primary: false },
  { text: 'Facturación', path: '/CostosDashboard', icon: <AttachMoneyIcon />, primary: false },
  { text: 'Status del Sistema', path: '/MEMDashboard', icon: <SolarPowerIcon />, primary: false },
  //{ text: 'Usuario', path: '/userprofile', icon: <AccountBoxIcon />, primary: false },
  { text: 'Directorio de Contactos', path: '/contactos', icon: <ContactsIcon />, primary: false },
  { text: 'Asistente Bulbo AI', path: '/MEMchatbot', icon: <SmartToyIcon />, primary: false },
  { text: 'Asistente DataBot', path: '/MEMdatabot', icon: <SmartToyIcon />, primary: false },
  { text: 'Simple DataBot', path: '/MEMdataBotSimple', icon: <SmartToyIcon />, primary: false },
  //{ text: 'Facturacion', path: '/facturacion', icon: <DescriptionIcon />, primary: false },
  { text: 'Logout', path: '/Login', icon: <DescriptionIcon />, primary: false },
  

];

function Sidebar() {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0(); 

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  
  return (
    <Box style={{ marginTop: '64px' }}>
      {isAuthenticated && (
        <>
          <Hidden smUp>
            <AppBar position='fixed' marginTop={10} color="primary">
              <Toolbar >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ 
                marginRight: 0, 
                marginLeft: 1,
                position:'sticky',
                top:10,
                marginTop: 6,
              }}
              
            >
              <MenuIcon />
            </IconButton>
            </Toolbar>
            </AppBar>
          </Hidden>
          <Hidden implementation="css" smDown>
            <StyledDrawer variant="permanent" open>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                height="5%"
              >
              </Box>
                <Box>
                  <Typography variant="h6" align="center" p={0}>
                    Bulbo
                  </Typography>
                  <Typography variant="h6" align="center" p={0}>
                    Technologies
                  </Typography>
                </Box>
                <Box flexGrow={1}>
                  <List>
                      {menuItems.map(({ text, path, icon, primary }) => (
                        <ActiveMenuItem
                        button
                        key={text}
                        component={NavLink}
                        to={path}
                        activeclassname="active"
                        onClick={() => {
                          if (text === 'Logout') {
                            logout({ returnTo: window.location.origin }); 
                            navigate('/login');
                          }
                        }}
                        >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              style={primary ? { fontWeight: 'bold' } : {}}
                            >
                              {text}
                            </Typography>
                          }
                        />
                      </ActiveMenuItem>
                    ))}
                  </List>
                </Box>
              </StyledDrawer>
            </Hidden>
            <Hidden implementation="css" smUp>
              <StyledDrawer height='10%' variant="temporary" anchor="top" open={open} onClose={handleDrawerToggle}>
                <List>
                  {menuItems.map(({ text, path, icon, primary }) => (
                    <ListItem button key={text} component={NavLink} to={path} onClick={handleDrawerToggle} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={<Typography variant="body1" style={primary ? { fontWeight: 'bold' } : {}}>{text}</Typography>} />
                    </ListItem>
                  ))}
                </List>
              </StyledDrawer>
            </Hidden>
          </>
        )}
      </Box>
    );
  }

  export default Sidebar;