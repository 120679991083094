import React, { useState, useContext, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import ChatbotContext from './ChatbotContext';
import styles from './ChatbotWindow.module.css';

const ChatbotWindow = () => {
  const [messages, setMessages] = useState([{ content: 'Bienvenida! si no encuentro respuesta, lo diré.', sender: 'bot' }]);
  const [inputMessage, setInputMessage] = useState('');
  const { sendMessage, isLoading } = useContext(ChatbotContext);
  const messagesEndRef = useRef(null);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: inputMessage }]);
    setInputMessage('');
  
    sendMessage(inputMessage, (response) => {
      console.log('Received response:', response);
  
      if (response && response.error) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'Bulbo AI', content: response.error },
        ]);
      } else if (response && response.text && response.text.output_text) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: response.sender, content: response.text.output_text },
        ]);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'Bulbo AI', content: 'Error: Unable to get chatbot response' },
        ]);
      }
    });
  };
  
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    console.log('Messages:', messages);
    scrollToBottom();
  }, [messages]);

  const TypingDots = () => (
    <span className={styles.typingDots}>
      <strong>AI:</strong> ...
    </span>
  );

  return (
    <Box>
      <div className={styles.chatbotWindow}>
        <div className={styles.chatMessages}>
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <strong>{message.role === 'user' ? 'You' : 'Bulbo AI'}:</strong> {message.content}
            </div>
          ))}
          {isLoading && <TypingDots />}
          <div ref={messagesEndRef}></div>
        </div>
        <div className={styles.chatInput}>
          <input
            type="text"
            value={inputMessage}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Escribe un mensaje..."
          />
          <button onClick={handleSendMessage} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </Box>
  );
};

export default ChatbotWindow;
