import React, { createContext, useContext, useState, useEffect, useCallback } from "react";

const API_BASE_URL = "https://bulbolabs.com";
const apiBulbocontext = createContext();

export const useApi = () => {
  return useContext(apiBulbocontext);
};

export const ApiProvider = ({ children }) => {
  
  const fetchData = useCallback(async (url, setData) => {
    
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      setData(data);
    }
  }, []);

  const [dailySummary, setDailySummary] = useState([]);
  const [gerencia10Data, setGerencia10Data] = useState([]);
  const [alertasEstadoOperativo, setAlertasEstadoOperativo] = useState([]);
  const [dailyIndicadores, setDailyIndicadores] = useState([]);
  const [monthlyTotal, setMonthlyTotal] = useState([]);
  const [dailyAverage, setDailyAverage] = useState([]);
  const [peakHourAverage, setPeakHourAverage] = useState([]);
  const [missingHours, setMissingHours] = useState([]);
  const [dailyMonthlyConsumption, setDailyMonthlyConsumption] = useState([]);
  const [peakHourDemand, setPeakHourDemand] = useState([]);
  const [contractedVsDailyDemand, setContractedVsDailyDemand] = useState([]);
  const [monthlyTrend, setMonthlyTrend] = useState([]);
  const [consumoMWh, setConsumoMWh] = useState([]);
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [costoUnitarioCfe, setCostoUnitarioCfe] = useState([]);
  const [costoMensual, setCostoMensual] = useState([]);
  const [ahorroMensual, setAhorroMensual] = useState([]);
  const [consumoMensual, setConsumoMensual] = useState([]);
  const [lastMonthData] = useState([]);


  useEffect(() => {
    fetchData(`${API_BASE_URL}/mem/daily_summary`, setDailySummary);
    fetchData(`${API_BASE_URL}/mem/alertas_estado_operativo`, setAlertasEstadoOperativo);
    fetchData(`${API_BASE_URL}/mem/daily_indicadores`, setDailyIndicadores);
    fetchData(`${API_BASE_URL}/consumos/monthly_total/usuario_1/2021/10`, setMonthlyTotal);
    fetchData(`${API_BASE_URL}/consumos/daily_average/usuario_1/2021/10`, setDailyAverage);
    fetchData(`${API_BASE_URL}/consumos/peak_hour_average/usuario_1/2021/10`, setPeakHourAverage);
    fetchData(`${API_BASE_URL}/consumos/missing_hours/usuario_1/2021/10`, setMissingHours);
    fetchData(`${API_BASE_URL}/consumos/daily_monthly_consumption/usuario_1/2021/10`, setDailyMonthlyConsumption);
    fetchData(`${API_BASE_URL}/consumos/peak_hour_demand/usuario_1/2021/10`, setPeakHourDemand);
    fetchData(`${API_BASE_URL}/consumos/contracted_vs_daily_demand/usuario_1/2021/10`, setContractedVsDailyDemand);
    fetchData(`${API_BASE_URL}/consumos/monthly_trend/usuario_1`, setMonthlyTrend);
    fetchData(`${API_BASE_URL}/consumos/consumo_mwh/usuario_1/2021/10`, setConsumoMWh);
    
  }, [fetchData]);

  useEffect(() => {
    setGerencia10Data(extractGerencia10Data(dailySummary));
  }, [dailySummary]);

  const extractGerencia10Data = (rawData) => {
    return rawData.filter((item) => item.gerencia === 10).map((item) => {
      return {
        date: item.date,
        hora: item.hora,
        precio_bca: item.precio_bca,
        precio_bcs: item.precio_bcs,
        precio_sin: item.precio_sin,
        valor_demanda: item.valor_demanda,
        valor_generacion: item.valor_generacion,
        valor_pronostico: item.valor_pronostico,
      };
    });
  };

  useEffect(() => {
    fetchData(`${API_BASE_URL}/costos/monthly_summary/usuario_1`, setMonthlySummary);
  }, [fetchData]);

  useEffect(() => {
    const lastMonthData = monthlySummary[monthlySummary.length - 1] || {};
    setCostoUnitarioCfe(lastMonthData.costo_unitario_cfe || '-');
    setCostoMensual(lastMonthData.costo_mensual_mdp || '-');
    setAhorroMensual(lastMonthData.ahorro_mensual || '-');
    setConsumoMensual(lastMonthData.consumo_mensual_mwh || '-');
  }, [monthlySummary]);

  const value = {
    dailySummary,
    gerencia10Data,
    alertasEstadoOperativo,
    dailyIndicadores,
    monthlyTotal,
    dailyAverage,
    peakHourAverage,
    missingHours,
    dailyMonthlyConsumption,
    peakHourDemand,
    contractedVsDailyDemand,
    monthlyTrend,
    consumoMWh,
    lastMonthData,
    costoUnitarioCfe,
    costoMensual,
    ahorroMensual,
    consumoMensual,
    monthlySummary,
  };

  return <apiBulbocontext.Provider value={value}>{children}</apiBulbocontext.Provider>;
}
