import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    console.log("User is authenticated, redirecting to Dashboard...");
    return <p>Redirecting...</p>;
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: (theme) => theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ margin: (theme) => theme.spacing(1), bgcolor: (theme) => theme.palette.secondary.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          sx={{ width: '100%', marginTop: (theme) => theme.spacing(3) }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => loginWithRedirect({ redirectUri: `${window.location.origin}/dashboard` })}>
          
            Sign In
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          © 2023 Bulbo Technologies. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
