import { Grid, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';
import CardInfo from '../common/CardInfo';
import BarChartCard from '../charts/BarChartCard';
import TariffChartCard from '../charts/TariffChartCard';
import ComposedChartCard from '../charts/ComposedChartCard';
import LineChartCard from '../charts/LineChartCard';
import DashboardLayout from '../common/dashboardLayout';
import { useApi } from '../context/apiBulbocontext';


function CostosDashboard() {
  const {
    costoUnitarioCfe,
    costoMensual,
    ahorroMensual,
    consumoMensual,
    monthlySummary,
  } = useApi();

  return (
    <DashboardLayout>
    <Container
    sx={{
    minHeight: '100vh',
    padding: { sm: '0', xs: '0' },
    }}>
      <Grid container spacing={1} padding={1}>
        <Grid item xs={12} md={12}>
            <Paper>
                <Typography variant='h4' padding = {1}>
                    Empresa Sa de CV
                </Typography>
                <Typography variant='h5' padding = {1}>
                    Octubre 2021
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Tarifa Unitaria"
          data={costoUnitarioCfe}
          subtitle="MXN / MWh"
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Costo Mensual "
          data = {costoMensual}
          subtitle="estimado en MDP"
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Ahorro Estimado"
          data = {ahorroMensual}
          subtitle="MDP vs CFE Básico"
          >
          </CardInfo>
        </Grid>
        <Grid item xs={6} md={3}>
          <CardInfo
          DataTitle="Consumo Mensual"
          data = {consumoMensual}
          subtitle="MWh"
          >
          </CardInfo>
        </Grid>
        <Grid item xs ={12} md ={6}>
            <BarChartCard
            title="Facturación Mensual"
            subtitle="MDP"
            data ={monthlySummary}
            xAxisDataKey ="year_month"
            barDataKey="costo_mensual_mdp"
            Description="Histórico de MWh consumidos por mes"
            >
            </BarChartCard>
        </Grid>
        <Grid item xs ={12} md ={6}>
            <TariffChartCard>
            </TariffChartCard>
        </Grid>
        <Grid item xs ={12} md ={6}>
            <ComposedChartCard
              title="Consumo y Costo Mensual"
              subtitle="MWh / MDP"
              data ={monthlySummary}
              xAxisDataKey ="year_month"
              lineDataKey ="costo_mensual_mdp"
              barDataKey = "consumo_mensual_mwh"
              description ="Relación entre energía consumida y costos"
            >
            </ComposedChartCard>
        </Grid>
        <Grid item xs ={12} md ={6}>
        <LineChartCard
              title="Ahorro Mensual"
              subtitle="MDP"
              data ={monthlySummary}
              xAxisDataKey ="year_month"
              LineDataKey = "ahorro_mensual"
              Description ="Histórico de ahorro mensual vs tarifa regulada"
            >
            </LineChartCard>
        </Grid>
      </Grid>
    </Container>
    </DashboardLayout>
  );
};
export default CostosDashboard;
