// In SourceFeed.js
import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SourceFeed = ({ source }) => {
  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    setSourceData(source);
  }, [source]);

  if (!sourceData || sourceData.length === 0) {
    return null;
  }

  return (
  
      <Box minHeight={650} maxHeight={650} overflow="auto">
        <List>
          {source.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="caption">{item.metadata.source}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListItemText
                  primary={item.page_content}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      </Box>
   
  );
};

export default SourceFeed;

