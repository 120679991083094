import React from 'react';
import { Avatar, Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../Assets/logoBLB.png';


function Navbar() {
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();

  const handleSignOut = () => {
    console.log('Signed out');
    logout({ returnTo: window.location.origin });
    navigate('/Login');
  };

  return (
    <Box padding ={1} >
    <AppBar variant="dense" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
      <Toolbar sx={{ marginLeft: '2px' }}>
       <Avatar src={logo} alt="LogoBT" variant="circular" sx={{ width: 34, height: 34, padding:1 }} />
        <Typography variant="h5" sx={{ flexGrow: 10 }}>
          Dashboard Energía
        </Typography>
        {isAuthenticated && (
          <IconButton edge="end" color="inherit" aria-label="sign out" onClick={handleSignOut}>
            <ExitToAppIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
    </Box>
  );
}

export default Navbar;
