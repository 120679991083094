import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Box } from '@mui/material';

const DashboardLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box 
      display="flex"
      maxWidth="false"
      marginTop={10}

      sx={{
        flexGrow: 1,
        p: 0,
        paddingTop: '10px',
        width: {  xs: '100%' },
      }}
      >
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 0, paddingTop: '0px', paddingRight: '0px' }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;