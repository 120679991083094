import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';

import CardInfo from '../common/CardInfo';
import DashboardLayout from '../common/dashboardLayout';

function Facturacion() {
  return (
    <DashboardLayout>
      <Container
      maxWidth="xl"
      marginTop={10}
      sx={{
        backgroundColor: '#faf9f6',
        minHeight: '100vh',
      }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardInfo
            DataTitle="Facturacion"
            />
            
          </Grid>
          

        </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default Facturacion;
